import { gql } from "@apollo/client";

export const DASHBOARD_STATS = gql`
  query DashboardStats($organizationId: ID!) {
    dashboardStats(organizationId: $organizationId) {
      ordersStats {
        unfulfilledOrders {
          ...StatsDataFragment
        }
        shippedOrders {
          ...StatsDataFragment
        }
        openedOrders {
          ...StatsDataFragment
        }
        erroredOrders {
          ...StatsDataFragment
        }
        orders {
          ...StatsDataFragment
        }
        expeditionSla
      }
      shipmentsStats {
        expiredShipments {
          ...StatsDataFragment
        }
        returnedToSenderShipments {
          ...StatsDataFragment
        }
        deliveredShipments {
          ...StatsDataFragment
        }
        exceptionShipments {
          ...StatsDataFragment
        }
        openedShipments {
          ...StatsDataFragment
        }
        shipments {
          ...StatsDataFragment
        }
        deliverySla
      }
    }
  }
  fragment StatsDataFragment on StatsData {
    total
    history {
      date
      value
    }
  }
`;
