import { useCallback } from "react";
import { useApplicationContext } from "../contexts/application.context";

interface DateTimeFormatOptions {
  year?: optionDateEnum,
  month?: optionDateEnum,
  day?: optionDateEnum,
  hour?: optionDateEnum,
  minute?: optionDateEnum,
  second?: optionDateEnum,
}

enum optionDateEnum {
  NUMERIC = "numeric",
  DIGIT = "2-digit"
}

export enum DateOptionEnum {
  DATE = "DATE",
  DATE_MONTH = "DATE_MONTH",
  DATE_TIME_MINUTES = "DATE_TIME_MINUTES",
  DATE_TIME_SECONDS = "DATE_TIME_SECONDS",
}

export const DateFormatOption: { [s in DateOptionEnum]: DateTimeFormatOptions } =
{
  [DateOptionEnum.DATE]: {
    year: optionDateEnum.NUMERIC,
    month: optionDateEnum.NUMERIC,
    day: optionDateEnum.NUMERIC,
  },
  [DateOptionEnum.DATE_MONTH]: {
    month: optionDateEnum.NUMERIC,
    day: optionDateEnum.NUMERIC,
  },
  [DateOptionEnum.DATE_TIME_MINUTES]: {
    year: optionDateEnum.NUMERIC,
    month: optionDateEnum.NUMERIC,
    day: optionDateEnum.NUMERIC,
    hour: optionDateEnum.NUMERIC,
    minute: optionDateEnum.NUMERIC,
  },
  [DateOptionEnum.DATE_TIME_SECONDS]: {
    year: optionDateEnum.NUMERIC,
    month: optionDateEnum.NUMERIC,
    day: optionDateEnum.NUMERIC,
    hour: optionDateEnum.NUMERIC,
    minute: optionDateEnum.NUMERIC,
    second: optionDateEnum.NUMERIC,
  },
};

export default function useFormatDate() {
  const { locale } = useApplicationContext();
  return useCallback(
    (date, option?: DateOptionEnum) => {
      if (!date) {
        return;
      }

      return new Intl.DateTimeFormat(
        locale,
        DateFormatOption[option || DateOptionEnum.DATE]
      ).format(date)
    }
    , [locale])
}
