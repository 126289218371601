import React, { useRef } from "react";
import {
  Card,
  TextContainer,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Stack,
  Heading,
  TextStyle,
  EmptyState,
} from "@shopify/polaris";
import ApplicationFrame from "../components/frame";
import useTranslator from "../hooks/i18n.hook";
import { useQuery } from "@apollo/client";
import { useApplicationContext } from "../contexts/application.context";
import { DASHBOARD_STATS } from "../queries/dashboard-stats.query";
import { DashboardStats } from "../queries/__generated__/DashboardStats";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Bar } from "react-chartjs-2";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import router from "next/router";
import useFormatDate, { DateFormatOption, DateOptionEnum } from "../hooks/intlDate.hook";

Chart.register(CategoryScale);

export default function Dashboard() {
  const i18n = useTranslator("Dashboard");
  const intlDate = useFormatDate();
  const { organizationId } = useApplicationContext();
  const { loading, data } = useQuery<DashboardStats>(DASHBOARD_STATS, {
    variables: {
      organizationId,
    },
  });
  const now = Date.now()
  const thirtyDaysBefore = now - 2592000000; // 30 * 24 * 60 * 60 * 1000
  const ordersStats = data?.dashboardStats?.ordersStats;
  const preparationStatsMarkup = ordersStats ? (
    <Stack.Item>
      <Card
        title={
          <>
            <Heading>{i18n.translate("Dashboard.Preparation.title")}</Heading>
            <TextStyle variation="subdued">
              {i18n.translate("Dashboard.Preparation.subtitle")}
            </TextStyle>
          </>
        }
      >
        <Card.Section
          title={ordersStats.openedOrders?.total}
          actions={[
            {
              content: i18n.translate("Dashboard.Preparation.action.show"),
              onAction: () => { window.open(`/orders?shipments.lastEvent=NEW&shipments.lastEvent=INFO_RECEIVED&shipments.lastEvent=PENDING&shipments.lastEvent=IN_TRANSIT&shipments.lastEvent=OUT_FOR_DELIVERY&shipments.lastEvent=ATTEMPT_FAIL&shipments.lastEvent=AVAILABLE_FOR_PICKUP&shipments.lastEvent=EXCEPTION&shipments.lastEvent=RETURNED_TO_SENDER&shipments.lastEvent=DELIVERED_TO_SENDER&shipments.lastEvent=EXPIRED&shipments.lastEvent=DELIVERED&issuedAtTimestamp>=${thirtyDaysBefore}&issuedAtTimestamp<=${now}`, '_ blank') },
            },
          ]}
        >
          {i18n.translate("Dashboard.Preparation.openedOrders")}
        </Card.Section>

        <Card.Section
          title={ordersStats.shippedOrders?.total}
          actions={[
            {
              content: i18n.translate("Dashboard.Preparation.action.show"),
              onAction: () => { window.open(`/orders?shipments.state=OPENED&shipments.state=DRAFT&issuedAtTimestamp>=${thirtyDaysBefore}&issuedAtTimestamp<=${now}`, '_ blank') },
            },
          ]}
        >
          {i18n.translate("Dashboard.Preparation.shippedOrders")}
        </Card.Section>

        <Card.Section
          title={ordersStats.erroredOrders?.total}
          actions={[
            {
              content: i18n.translate("Dashboard.Preparation.action.show"),
              onAction: () => { window.open(`/orders?_tags=DISPATCHING_ERROR&issuedAtTimestamp>=${thirtyDaysBefore}&issuedAtTimestamp<=${now}`, '_ blank') },
            },
          ]}
        >
          {i18n.translate("Dashboard.Preparation.erroredOrders")}
        </Card.Section>

        <Card.Section
          title={ordersStats.unfulfilledOrders?.total}
          actions={[
            {
              content: i18n.translate("Dashboard.Preparation.action.show"),
              onAction: () => { window.open(`/orders?_tags=FULFILLMENT_NOT_REQUESTED&_tags=FULFILLMENT_WARNING&issuedAtTimestamp>=${thirtyDaysBefore}&issuedAtTimestamp<=${now}`, '_ blank') },
            },
          ]}
        >
          {i18n.translate("Dashboard.Preparation.unfulfilledOrders")}
        </Card.Section>
      </Card>
    </Stack.Item>
  ) : null;

  const shippingStats = data?.dashboardStats?.shipmentsStats;
  const shippingStatsMarkup = shippingStats ? (
    <Stack.Item>
      <Card
        title={
          <>
            <Heading>{i18n.translate("Dashboard.Shipping.title")}</Heading>
            <TextStyle variation="subdued">
              {i18n.translate("Dashboard.Shipping.subtitle")}
            </TextStyle>
          </>
        }
      >
        <Card.Section
          title={
            shippingStats.openedShipments?.total
          }
          actions={[
            {
              content: i18n.translate("Dashboard.Shipping.action.show"),
              onAction: () => {
                window.open(`/orders?shipments.lastEvent=NEW&shipments.lastEvent=INFO_RECEIVED&shipments.lastEvent=PENDING&shipments.lastEvent=IN_TRANSIT&shipments.lastEvent=OUT_FOR_DELIVERY&shipments.lastEvent=ATTEMPT_FAIL&shipments.lastEvent=AVAILABLE_FOR_PICKUP&shipments.lastEvent=EXCEPTION&shipments.lastEvent=RETURNED_TO_SENDER&issuedAtTimestamp>=${thirtyDaysBefore}&issuedAtTimestamp<=${now}`, '_ blank')
              },
            },
          ]}
        >
          {i18n.translate("Dashboard.Shipping.openedShipments")}
        </Card.Section>

        <Card.Section
          title={shippingStats.deliveredShipments.total}
          actions={[
            {
              content: i18n.translate("Dashboard.Shipping.action.show"),
              onAction: () => { window.open(`/orders?shipments.lastEvent=DELIVERED&issuedAtTimestamp>=${thirtyDaysBefore}&issuedAtTimestamp<=${now}`, '_ blank') }
            },
          ]}
        >
          {i18n.translate("Dashboard.Shipping.deliveredShipments")}
        </Card.Section>

        <Card.Section
          title={shippingStats.expiredShipments.total}
          actions={[
            {
              content: i18n.translate("Dashboard.Shipping.action.show"),
              onAction: () => { window.open(`/orders?shipments.lastEvent=EXPIRED&issuedAtTimestamp>=${thirtyDaysBefore}&issuedAtTimestamp<=${now}`, '_ blank') },
            },
          ]}
        >
          {i18n.translate("Dashboard.Shipping.expiredShipments")}
        </Card.Section>

        <Card.Section
          title={shippingStats.returnedToSenderShipments.total}
          actions={[
            {
              content: i18n.translate("Dashboard.Shipping.action.show"),
              onAction: () => { window.open(`/orders?shipments.lastEvent=DELIVERED_TO_SENDER&shipments.lastEvent=RETURNED_TO_SENDER&issuedAtTimestamp>=${thirtyDaysBefore}&issuedAtTimestamp<=${now}`, '_ blank') },
            },
          ]}
        >
          {i18n.translate("Dashboard.Shipping.returnedToSenderShipments")}
        </Card.Section>
      </Card>
    </Stack.Item>
  ) : null;

  const getCircularProgressColor = (ratio: number) => {
    if (ratio < 50) {
      return '#FED3D1'
    } else if (ratio >= 50 && ratio < 75) {
      return '#FFD79D'
    } else {
      return '#2FD8B6'
    }
  }

  const expeditionSla = ordersStats?.expeditionSla;
  const expeditionSlaMarkup =
    !loading ? (
      <Stack.Item>
        {expeditionSla > 0 ? (
          <Card
            title={
              <>
                <Heading>
                  {i18n.translate("Dashboard.Preparation.expeditionSla.title")}
                </Heading>
                <TextStyle variation="subdued">
                  {i18n.translate(
                    "Dashboard.Preparation.expeditionSla.subtitle"
                  )}
                </TextStyle>
              </>
            }
          >
            <Card.Section>
              <div
                style={{
                  width: 200,
                  height: 322,
                  margin: "0 auto",
                  paddingTop: "50px",
                }}
              >
                <CircularProgressbar
                  value={Math.round(expeditionSla * 100)}
                  strokeWidth={4}
                  text={`${Math.round(expeditionSla * 100)}%`}
                  styles={buildStyles({
                    pathColor: getCircularProgressColor(Math.round(expeditionSla * 100)),
                    trailColor: "transparent",
                    textColor: "#202223",
                  })}
                />
              </div>
            </Card.Section>
          </Card>
        ) : (
          <Card sectioned>
            <EmptyState
              heading={i18n.translate(
                "Dashboard.Preparation.expeditionSla.title"
              )}
              image=""
            >
              <p>
                {i18n.translate(
                  "Dashboard.Preparation.expeditionSla.noSlaSubtitle"
                )}
              </p>
            </EmptyState>
          </Card>
        )}
      </Stack.Item>
    ) : null;

  const shippingSla = shippingStats?.deliverySla;
  const shippingSlaMarkup =
    !loading ? (
      <Stack.Item>
        {shippingSla > 0 ? (
          <Card
            title={
              <>
                <Heading>
                  {i18n.translate("Dashboard.Preparation.shippingSla.title")}
                </Heading>
                <TextStyle variation="subdued">
                  {i18n.translate("Dashboard.Preparation.shippingSla.subtitle")}
                </TextStyle>
              </>
            }
          >
            <Card.Section>
              <div
                style={{
                  width: 200,
                  height: 322,
                  margin: "0 auto",
                  paddingTop: "50px",
                }}
              >
                <CircularProgressbar
                  value={Math.round(shippingSla * 100)}
                  strokeWidth={4}
                  text={`${Math.round(shippingSla * 100)}%`}
                  styles={buildStyles({
                    pathColor: getCircularProgressColor(Math.round(shippingSla * 100)),
                    trailColor: "transparent",
                    textColor: "#202223",
                  })}
                />
              </div>
            </Card.Section>
          </Card>
        ) : (
          <Card sectioned>
            <EmptyState
              heading={i18n.translate(
                "Dashboard.Preparation.shippingSla.title"
              )}
              image=""
            >
              <p>
                {i18n.translate(
                  "Dashboard.Preparation.shippingSla.noSlaSubtitle"
                )}
              </p>
            </EmptyState>
          </Card>
        )}
      </Stack.Item>
    ) : null;

  const labels = ordersStats?.shippedOrders?.history.map((h) =>
    h.date
  );

  const preparationChartOptions = {
    plugins: {
      title: {
        display: false,
        text: "Préparation de commandes",
      },
      legend: {
        position: 'bottom' as 'bottom',
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          align: "center" as "center",
          callback: function (value, index, ticks) {
            return intlDate(new Date(labels[index]), DateOptionEnum.DATE_MONTH);
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
        },
      },
    },
  };

  const preparationChartData = {
    labels,
    datasets: [
      {
        label: i18n.translate("Dashboard.Preparation.chart.open"),
        data: ordersStats?.openedOrders?.history.map((h) => h.value),
        backgroundColor: "#A4E8F2",
        stack: "Stack 0",
      },
      {
        label: i18n.translate("Dashboard.Preparation.chart.sent"),
        data: ordersStats?.shippedOrders?.history.map((h) => h.value),
        backgroundColor: "#2FD8B6",
        stack: "Stack 0",
      },
      {
        label: i18n.translate("Dashboard.Preparation.chart.inProgress"),
        data: ordersStats?.unfulfilledOrders?.history.map((h) => h.value),
        stack: "Stack 0",
        backgroundColor: "#FFD79D",
      },
      {
        label: i18n.translate("Dashboard.Preparation.chart.error"),
        data: ordersStats?.erroredOrders?.history.map((h) => h.value),
        stack: "Stack 0",
        backgroundColor: "#FED3D1",
      },
    ],
  };
  const preparationChartMarkup = ordersStats ? (
    <Card>
      <div
        style={{
          position: "relative" as "relative",
          width: "537px",
          height: "300px",
          padding: "1rem",
        }}
      >
        <Bar options={preparationChartOptions} data={preparationChartData} />
      </div>
    </Card>
  ) : null;

  const shippingChartData = {
    labels,
    datasets: [
      {
        label: i18n.translate("Dashboard.Shipping.chart.inProgress"),
        data: shippingStats?.openedShipments?.history.map((h) => h.value),
        backgroundColor: "#A4E8F2",
        stack: "Stack 0",
      },
      {
        label: i18n.translate("Dashboard.Shipping.chart.delivered"),
        data: shippingStats?.deliveredShipments?.history.map((h) => h.value),
        stack: "Stack 0",
        backgroundColor: "#2FD8B6",
      },
      {
        label: i18n.translate("Dashboard.Shipping.chart.sentBack"),
        data: shippingStats?.returnedToSenderShipments?.history.map(
          (h) => h.value
        ),
        stack: "Stack 0",
        backgroundColor: "#FFD79D",
      },
      {
        label: i18n.translate("Dashboard.Shipping.chart.error"),
        data: shippingStats?.expiredShipments?.history.map((h) => h.value),
        stack: "Stack 0",
        backgroundColor: "#FED3D1",
      },
    ],
  };
  const shippingChartMarkup = shippingStats ? (
    <Card>
      <div
        style={{
          position: "relative" as "relative",
          width: "537px",
          height: "300px",
          padding: "1rem",
        }}
      >
        <Bar options={preparationChartOptions} data={shippingChartData} />
      </div>
    </Card>
  ) : null;

  const actualPageMarkup = !loading ? (
    <Page fullWidth>
      <Layout>
        <Layout.Section>
          <div className="stats-wrapper">
            <Stack>
              <Stack.Item fill></Stack.Item>
              <Stack.Item>
                <div className="stats-heading">
                  <Heading element="h1">
                    {i18n.translate("Dashboard.title")}
                  </Heading>
                </div>
                <Stack distribution="trailing">
                  {expeditionSlaMarkup}
                  {preparationStatsMarkup}
                  {shippingStatsMarkup}
                  {shippingSlaMarkup}
                </Stack>
              </Stack.Item>
              <Stack.Item fill></Stack.Item>
            </Stack>
          </div>
        </Layout.Section>
        <Layout.Section>
          <Stack>
            <Stack.Item fill></Stack.Item>
            <Stack.Item>
              <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                <Heading element="h1">
                  {i18n.translate("Dashboard.historyTitle")}
                </Heading>

                <TextStyle variation="subdued">
                  {i18n.translate(
                    "Dashboard.Preparation.expeditionSla.subtitle"
                  )}
                </TextStyle>
              </div>
              <Stack>
                <Stack.Item>{preparationChartMarkup}</Stack.Item>
                <Stack.Item>{shippingChartMarkup}</Stack.Item>
              </Stack>
            </Stack.Item>
            <Stack.Item fill></Stack.Item>
          </Stack>
        </Layout.Section>
      </Layout>
    </Page>
  ) : null;

  const loadingPageMarkup = loading ? (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  ) : null;

  return (
    <ApplicationFrame>
      {actualPageMarkup}
      {loadingPageMarkup}
    </ApplicationFrame>
  );
}
